export const formatExpirationDate = expirationDate => {
  let cardMonth = expirationDate.charAt(0) + expirationDate.charAt(1)
  let cardYear =
    "20" +
    expirationDate.charAt(expirationDate.length - 2) +
    expirationDate.charAt(expirationDate.length - 1)
  let todayMonth = new Date().getMonth() + 1
  let todayYear = new Date().getFullYear()
  if (todayMonth > cardMonth && todayYear >= cardYear) {
    return "expired"
  } else if (cardMonth === "01") {
    return "Jan " + cardYear
  } else if (cardMonth === "02") {
    return "Feb " + cardYear
  } else if (cardMonth === "03") {
    return "Mar " + cardYear
  } else if (cardMonth === "04") {
    return "Apr " + cardYear
  } else if (cardMonth === "05") {
    return "May " + cardYear
  } else if (cardMonth === "06") {
    return "Jun " + cardYear
  } else if (cardMonth === "07") {
    return "Jul " + cardYear
  } else if (cardMonth === "08") {
    return "Aug " + cardYear
  } else if (cardMonth === "09") {
    return "Sep " + cardYear
  } else if (cardMonth === "10") {
    return "Oct " + cardYear
  } else if (cardMonth === "11") {
    return "Nov " + cardYear
  } else if (cardMonth === "12") {
    return "Dec " + cardYear
  }
}

export const getCardIcon = cardMaker => {
  cardMaker = cardMaker.toLowerCase()
  if (cardMaker === "amex") {
    return "fab fa-cc-amex"
  } else if (cardMaker === "diners-club") {
    return "fab fa-cc-diners-club"
  } else if (cardMaker === "discover") {
    return "fab fa-cc-discover"
  } else if (cardMaker === "jcb") {
    return "fab fa-cc-jcb"
  } else if (cardMaker === "mc") {
    return "fab fa-cc-mastercard"
  } else if (cardMaker === "paypal") {
    return "fab fa-cc-paypal"
  } else if (cardMaker === "stripe") {
    return "fab fa-cc-stripe"
  } else if (cardMaker === "visa") {
    return "fab fa-cc-visa"
  } else if (cardMaker === "card") {
    return "fas fa-credit-card"
  } else if (cardMaker === "google-wallet") {
    return "fab fa-google-wallet"
  } else {
    return "fas fa-credit-card"
  }
}

export const convertAmountToTwoDecimalPlace = amount =>
  parseFloat(amount).toFixed(2)
export const convertUSDollarsCurrency3LettersToCurrencySymbol =
  currencyLetters => {
    if (currencyLetters === "USD") {
      return "$"
    } else {
      return currencyLetters
    }
  }

export const capitalizeFirstLetterOfCardName = cardMaker => {
  return cardMaker.charAt(0).toUpperCase() + cardMaker.slice(1)
}

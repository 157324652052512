import modalimage2 from "assets/images/product/img-4.png"
import modalimage1 from "assets/images/product/img-7.png"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { userDashboardData } from "helpers/requests"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  MdCallMade,
  MdFormatListNumbered,
  MdOutlinedFlag,
  MdOutlineDialpad,
  MdOutlinePaid,
  MdPeopleOutline,
} from "react-icons/md"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import LatestTransaction from "../../pages/PurchaseHistory/index"
import AutoTopUp from "./AutoTopUp"
//import Charts
import StackedColumnChart from "./StackedColumnChart"
// Pages Components
import WelcomeComp from "./WelcomeComp"
const user = JSON.parse(localStorage.getItem("userData")) || {}

const mdIcon = {
  MdCallMade: <MdCallMade />,
  MdFormatListNumbered: <MdFormatListNumbered />,
  MdOutlinedFlag: <MdOutlinedFlag />,
  MdOutlineDialpad: <MdOutlineDialpad />,
  MdPeopleOutline: <MdPeopleOutline />,
  MdOutlinePaid: <MdOutlinePaid />,
}

const Dashboard = () => {
  const [reports, setReports] = useState([
    {
      title: "Total Calls",
      iconClass: "bx-copy-alt",
      description: "1,235",
    },
    {
      title: "Revenues",
      iconClass: "bx-archive-in",
      description: "$35, 723",
    },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$0.2",
    },
    {
      title: "Total Calls",
      iconClass: "bx-copy-alt",
      description: "1,235",
    },
    {
      title: "Revenues",
      iconClass: "bx-archive-in",
      description: "$35, 723",
    },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$0.2",
    },
  ])
  const [email, setEmail] = useState([
    { title: "Week", linkto: "#", isActive: false },
    { title: "Month", linkto: "#", isActive: false },
    { title: "Year", linkto: "#", isActive: true },
  ])
  const [modal, setModal] = useState(false)
  const [dashboardReport, setDashboardReport] = useState([])
  const [chartValue, setChartValue] = useState([])
  const [chartColor, setChartColor] = useState("")
  const [chartData, setChartData] = useState([])
  const [chartYear, setChartYear] = useState("")
  const [load, setLoad] = useState(false)

  const togglemodal = () => {
    setModal(prevState => ({
      modal: !prevState.modal,
    }))
  }

  const fetchUserDashboardData = async () => {
    const data = {
      orgId: "939001",
      userGlobalId: user?.userGlobalId,
    }
    const dashboardData = await userDashboardData(data)
    const dashboardDataList = dashboardData?.data
    const callHistory = dashboardDataList?.callHistoryData
    const dashboardLog = dashboardDataList?.miniCardData
    if (dashboardDataList) {
      setDashboardReport(dashboardLog)
      setChartValue(callHistory.value)
      setChartColor(callHistory.color)
      setChartData(callHistory.data)
      setChartYear(callHistory.year)
      setLoad(true)
    }
  }

  useEffect(() => {
    fetchUserDashboardData()
  }, [])
  console.log(dashboardReport)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"Home"} breadcrumbItem={"Dashboard"} />

          <Row mb-0>
            <Col xl="4">
              <WelcomeComp />
              <AutoTopUp />
            </Col>

            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {dashboardReport.map((report, key) => (
                  <>
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div style={{ height: "80px" }}>
                            <Media>
                              <Media body>
                                <p className="text-muted font-size-12 font-weight-medium">
                                  {report.label}
                                </p>
                                <h4 className="mb-0 font-size-13">
                                  {report.value}
                                </h4>
                              </Media>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span
                                  style={{ fontSize: "24px" }}
                                  className="avatar-title"
                                >
                                  {mdIcon[report.icon]}
                                </span>
                              </div>
                            </Media>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4 float-sm-left">
                    Call History in {chartYear}
                  </CardTitle>
                  <div className="float-sm-right">
                    <ul className="nav nav-pills">
                      {email.map((mail, key) => (
                        <li className="nav-item" key={"_li_" + key}>
                          <Link
                            className={
                              mail.isActive ? "nav-link active" : "nav-link"
                            }
                            to={mail.linkto}
                          >
                            {mail.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="clearfix" />
                  {load ? (
                    <StackedColumnChart
                      chartValue={chartValue}
                      chartColor={chartColor}
                      chartData={chartData}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row mt-0>
            <Col lg="12">
              <LatestTransaction />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
        toggle={togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Order Details</ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-right">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={togglemodal}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default Dashboard
